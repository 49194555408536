import styled from 'styled-components';

const LoginContainer = styled.div`
	display: inline-block;
	position: relative;
	box-sizing: border-box;

	width: 100%;
	max-width: 1400px;
	justify-content: center;

	@media screen and (max-width: 991px) {
		justify-content: center;
	}
`;

export default LoginContainer;
