import styled from 'styled-components';

import newLoginBG from '../../assets/images/new-login-bg.png';

const LoginWrapper = styled.section`
	width: 100%;
	min-height: 100vh;
	display: flex;
	background: rgba(50, 99, 214, 0.06);
	background-size: cover;
	overflow: auto;
	align-items: center;
	justify-content: center;
	padding: 20px;
	scrollbar-color: #c4c4c4;
	scrollbar-width: thin;
	@media screen and (max-width: 1200px) {
		padding: 40px 50px 40px 100px;
	}
	@media screen and (max-width: 991px) {
		/* background: url(${newLoginBG}) no-repeat; */
		background-size: cover;
		padding: 40px 0px 40px 0px;
		background-position: -10px 0px;
	}
`;

export default LoginWrapper;
