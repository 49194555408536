import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Modal,
	ModalInnerWrapper,
	ModalBody,
	ModalFooter,
	Button,
	Panel,
	Row,
	Paragraph,
	// Icon,
} from '../stories';
import CloseIcon from '../stories/assets/images/icons/close_icon.svg';

import TickIcon from '../stories/assets/images/icons/tick_icon.svg';

const ConfirmBox = (props) => {
	const { close, children, callback, buttonName = 'Submit' } = props;

	const { isMessage } = useSelector((state) => {
		const { isMessage = false } = state.toastStore;
		return { isMessage };
	});

	const btnConfig = { disabled: false, name: buttonName };
	const [buttonConfig, setButtonConfig] = useState(btnConfig);

	useEffect(() => {
		setButtonConfig(btnConfig);
		return () => {
			setButtonConfig(btnConfig);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMessage]);

	const submitProcess = () => {
		setButtonConfig({ disabled: true, name: buttonName });
	};
	return (
		<Modal show>
			<ModalInnerWrapper box>
				<ModalBody padding="30px 20px 10px">
					<Panel>
						<Row centerAlign>
							<Paragraph textBlueNM textCenter BoldParagraph marginBottom="0px">
								{children}
							</Paragraph>
						</Row>
					</Panel>
				</ModalBody>
				<ModalFooter>
					<Row
						style={{
							justifyContent: 'center',
						}}
					>
						<Button
							smallButton
							ButtonLink
							style={{ padding: '6px', height: '33px', fontSize: '14px', marginRight: '25px' }}
							onClick={close}
						>
							<img
								src={CloseIcon}
								alt="Profile"
								style={{
									height: '20px',
									/* width: 20px; */
									margin: '0px 8px 0px 0px',
								}}
							/>
							Cancel
						</Button>
						{/* <Button
							ButtonHeightSmall
							primaryBorder
							id="btnCancelVital"
							name="btnCancelVital"
							onClick={() => handleModal()}
						>
							<Icon iconName="icon-close" />
							Cancel
						</Button> */}
						<Button
							id="btnSaveVital"
							name="btnSaveVital"
							ButtonHeightSmall
							primary
							style={{
								padding: '6px',
								height: '33px',
								fontSize: '14px',
								paddingRight: '15px',
								paddingLeft: '15px',
							}}
							onClick={() => {
								submitProcess();
								callback();
							}}
						>
							<img
								src={TickIcon}
								alt="Profile"
								style={{
									height: '20px',
									/* width: 20px; */
									margin: '0px 8px 0px -5px',
								}}
							/>
							{buttonConfig.name}
						</Button>
					</Row>
				</ModalFooter>
			</ModalInnerWrapper>
		</Modal>
	);
};

ConfirmBox.defaultProps = {
	callback: () => {},
	close: () => {},
};

export default ConfirmBox;
