import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/storage';
import { ClinicUnits, Roles } from '../helpers/Constants';
// import { getLocalStorageValueAsJSON } from '../pages/Login/login.store';

const firebaseConfig = {
	apiKey: 'AIzaSyDzro-5kH9k_qvnpgkAfWtOuszNN0C5M2A',
	authDomain: 'wecare-abnormality-reports.firebaseapp.com',
	projectId: 'wecare-abnormality-reports',
	storageBucket: 'wecare-abnormality-reports.appspot.com',
	messagingSenderId: '270177185156',
	appId: '1:270177185156:web:7ef11b37abc05937c6c1d1',
};

let dataBase = null;
let firebaseAdmin = null;

export const initializeFirebaseApp = async () => {
	await firebase.initializeApp(firebaseConfig);
	firebaseAdmin = await firebase.initializeApp(firebaseConfig, 'Secondary');
	dataBase = await firebase.firestore();
};

export const getFileStorage = (fileName) => firebase.storage().ref(fileName);

export const AuthenticateUser = (credential) =>
	firebase
		.auth()
		.signInWithEmailAndPassword(credential.email, credential.password)
		.then((userCredential) => userCredential);
// .catch((error) => error);

export const PostData = async (
	collectionName,
	data,
	collectionId = '',
	isSubCollection = false,
	subCollectionName = ''
) => {
	if (isSubCollection) {
		return dataBase
			.collection(collectionName)
			.doc(collectionId)
			.collection(subCollectionName)
			.add(data)
			.then((doc) => doc);
	}
	return dataBase
		.collection(collectionName)
		.add(data)
		.then((doc) => doc);
};

export const Get = async (
	collectionName,
	collectionId = '',
	isSubCollection = false,
	subCollectionName = ''
) => {
	if (isSubCollection) {
		return dataBase
			.collection(collectionName)
			.doc(collectionId)
			.collection(subCollectionName)
			.get();
	}
	return dataBase.collection(collectionName).doc(collectionId).get();
};

export const CheckPatientAvailability = async (collectionName, searchParam) => {
	let isDataAvailable = false;
	try {
		await dataBase
			.collection(collectionName)
			.where('isActive', '==', true)
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					if (searchParam) {
						const data = doc.data();
						if (
							data.dob.includes(searchParam.dob) &&
							data.username.includes(searchParam.username)
						) {
							isDataAvailable = true;
						}
					}
				});
			})
			.catch((error) => {
				console.log('ERROR ON PROCESS', error);
			});
	} catch (error) {
		console.log('ERROR ON PROCESS', error);
	}
	return { isPatientAvailable: isDataAvailable };
};

export const CheckAdminAvailability = async (clinicId, email = null) => {
	let isDataAvailable = false;
	await dataBase
		.collection('Users')
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (email) {
					const data = doc.data();
					if (data.email === email) {
						isDataAvailable = data;
					}
				}
			});
		});

	return { isAdminAvailable: isDataAvailable };
};

export const CheckUserAvailability = async (email = null) => {
	let isDataAvailable = false;
	await dataBase
		.collection('Users')
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (email) {
					const data = doc.data();
					console.log('EMAIL', data.email, data.email.toLowerCase() === email.toLowerCase());
					if (data.email.toLowerCase() === email.toLowerCase()) {
						isDataAvailable = true;
					}
				}
			});
		})
		.catch((error) => {
			console.log('CATCH--ERRROR', error);
		});

	return { isUserAvailable: isDataAvailable };
};

export const GetClinicUnit = async () => {
	const clinicUnit = ClinicUnits;
	// const clinicId = await getLocalStorageValueAsJSON('clinicId');
	// await dataBase
	// 	.collection('Clinics')
	// 	.doc(clinicId)
	// 	.collection('ClinicUnit')
	// 	.get()
	// 	.then((querySnapshot) => {
	// 		querySnapshot.forEach((doc) => {
	// 			clinicUnit = doc.data();
	// 		});
	// 	});
	return clinicUnit;
};

export const GetData = async (collectionName, searchKeyword = '', params = {}) => {
	function compareASC(d1, d2) {
		if (d1.data[params.sortBy] > d2.data[params.sortBy]) {
			return 1;
		}
		if (d1.data[params.sortBy] < d2.data[params.sortBy]) {
			return -1;
		}
		return 0;
	}
	function compareDESC(d1, d2) {
		if (d1.data[params.sortBy] < d2.data[params.sortBy]) {
			return 1;
		}
		if (d1.data[params.sortBy] > d2.data[params.sortBy]) {
			return -1;
		}
		return 0;
	}
	let dataList = [];
	// const clinicId = await getLocalStorageValueAsJSON('clinicId');
	await dataBase
		.collection(collectionName)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (searchKeyword.trim().length > 0) {
					const data = doc.data();
					if (
						data.patientUid.toLowerCase().includes(searchKeyword.toLowerCase()) ||
						data.username.toLowerCase().includes(searchKeyword.toLowerCase())
					) {
						dataList.push({ id: doc.id, data });
					}
				} else {
					dataList.push({ id: doc.id, data: doc.data() });
				}
			});
		});

	dataList = await dataList.sort((d1, d2) => d1.data.createdAt - d2.data.createdAt);
	if (params.sortBy) {
		if (params.order === 'asc') {
			dataList = await dataList.sort(compareASC);
		} else {
			dataList = await dataList.sort(compareDESC);
		}
	}
	if (params.gender && params.gender !== 'all') {
		dataList = await dataList.filter((d1) => d1.data.gender === params.gender);
	}
	return dataList;
};

export const PutData = (
	collectionName,
	collectionId,
	data,
	isSubCollection = false,
	subCollectionName = '',
	subCollectionId = ''
) => {
	if (isSubCollection) {
		return dataBase
			.collection(collectionName)
			.doc(collectionId)
			.collection(subCollectionName)
			.doc(subCollectionId)
			.set(data);
	}
	console.log('Collection', collectionName);
	console.log('CollectionID', collectionId);
	return dataBase.collection(collectionName).doc(collectionId).set(data);
};

export const DeleteDataById = (collectionName, collectionId) =>
	dataBase.collection(collectionName).doc(collectionId).delete();

export const GetList = async (collectionName, searchKeyword = '') => {
	function compareDESC(d1, d2) {
		if (d1.data.createdAt < d2.data.createdAt) {
			return 1;
		}
		if (d1.data.createdAt > d2.data.createdAt) {
			return -1;
		}
		return 0;
	}
	let dataList = [];
	await dataBase
		.collection(collectionName)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				data.id = doc.id;
				if (searchKeyword.trim().length > 0) {
					data.symptoms = data.symptoms.filter(
						(symptoms) =>
							symptoms.symptom.toLowerCase().includes(searchKeyword.toLowerCase()) ||
							symptoms.bodySide.toLowerCase().includes(searchKeyword.toLowerCase())
					);
					if (data.symptoms.length > 0) dataList.push({ id: doc.id, data });
				} else {
					dataList.push({ id: doc.id, data });
				}
			});
		});
	dataList = await dataList.sort(compareDESC);
	return dataList;
};

export const GetClinicDataById = async (collectionName, collectionId) => {
	const baseData = await dataBase
		.collection(collectionName)
		.doc(collectionId)
		.get()
		.then((querySnapshot) => ({
			data: querySnapshot.data(),
		}));
	baseData.data.id = collectionId;
	const clinicAdmins = [];
	await dataBase
		.collection(collectionName)
		.doc(collectionId)
		.collection('ClinicAdmins')
		.where('isActive', '==', true)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				data.id = doc.id;
				clinicAdmins.push(data);
			});
		});
	await clinicAdmins.sort((d1, d2) => d1.createdAt - d2.createdAt);
	baseData.data.clinicAdmins = clinicAdmins;
	return baseData;
};

export const GetDataById = async (collectionName, collectionId) => {
	const baseData = await dataBase
		.collection(collectionName)
		.doc(collectionId)
		.get()
		.then((querySnapshot) => ({
			data: querySnapshot.data(),
		}));
	baseData.data.id = collectionId;
	return baseData;
};

const GetUserId = async (userId) => {
	let id = null;
	await dataBase
		.collection('Users')
		.where('userId', '==', userId)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				id = doc.id;
			});
		});
	console.log('USER ID,', id);
	return id;
};

export const CreateUser = (data) => {
	if (data && data.isLoginEnabled) {
		return firebaseAdmin
			.auth()
			.createUserWithEmailAndPassword(data.email, data.password)
			.then((userCredential) => {
				const { user = {} } = userCredential;
				if (user.uid) {
					data.uid = user.uid;
					data.isActive = data.isLoginEnabled;
					data.uid = user.uid;
					PostData('Patients', data).then((newUser) => {
						data.userId = newUser.id;
						PostData('Users', data);
					});
				} else {
					console.log('NO USER_UID', userCredential);
				}
			})
			.catch((error) => console.log('ERROR ON USER CREATION', error));
	}
	data.isActive = data.isLoginEnabled;
	console.log('I ma before user user');
	return PostData('Patients', data).then((newUser) => {
		data.userId = newUser.id;
		console.log('I ma Created user');
		PostData('Users', data);
	});
};

export const UpdateUser = async (data, patientId, isEnabled) => {
	if (data && data.isLoginEnabled && !isEnabled) {
		return firebaseAdmin
			.auth()
			.createUserWithEmailAndPassword(data.email, data.password)
			.then((userCredential) => {
				const { user = {} } = userCredential;
				if (user.uid) {
					data.uid = user.uid;
					data.isActive = true;
					data.uid = user.uid;
					PutData(`Patients`, patientId, data).then(() => {
						data.userId = patientId;
						GetUserId(patientId).then((id) => {
							if (id) {
								PutData(`Users`, id, data);
							}
						});
					});
				} else {
					console.log('NO USER_UID', userCredential);
				}
			})
			.catch((error) => {
				console.log('ERROR on USER updates');
				data.isActive = true;
				PutData(`Patients`, patientId, data).then(() => {
					data.userId = patientId;
					GetUserId(patientId).then((id) => {
						if (id) {
							PutData(`Users`, id, data);
						}
					});
				});
			});
	}
	if (data && !data.isLoginEnabled && isEnabled) {
		console.log('I am not enabled');
		return PutData(`Patients`, patientId, data).then(() => {
			data.userId = patientId;
			data.isActive = false;
			GetUserId(patientId).then((id) => {
				if (id) {
					PutData(`Users`, id, data);
				}
			});
		});
	}
	console.log('I am no user', data.isLoginEnabled, isEnabled);
	data.isActive = data.isLoginEnabled;
	return PutData(`Patients`, patientId, data);
};

export const GetAuthenticatedUser = async (collectionName, uid) => {
	const baseData = await dataBase
		.collection(collectionName)
		.where('uid', '==', uid)
		.get()
		.then((querySnapshot) => {
			if (querySnapshot.empty) {
				return { data: { role: Roles.weCareAdmin, name: 'Super admin', isActive: true } };
			}
			const data = querySnapshot.docs[0].data();
			return { data };
		});
	return baseData;
};

const deleteAdmin = async (clinicId, docId) => {
	dataBase.collection('Clinics').doc(clinicId).collection('ClinicAdmins').doc(docId).delete();
};

export const deleteDataById = async (collectionName, collectionId) => {
	dataBase.collection(collectionName).doc(collectionId).delete();
};

export const DeactivateUser = (data) =>
	PutData('Users', data.userId, data).then(() => {
		deleteAdmin(data.clinicID, data.id);
	});

export const activateUser = (data) =>
	PutData('Users', data.userId, data).then(() => {
		PostData('Clinics', data, data.clinicID, true, 'ClinicAdmins');
	});
