import { GET, POST } from '../../service/HttpService';

import { extractMessageFrom } from '../../helpers/Utilities';
import { APP_TOKEN, snackBarErrorTypes, Roles, REFRESH_TOKEN } from '../../helpers/Constants';

import { showSnackbar } from '../../components/Snackbar/snackbar.store';
import {
	AuthenticateUser,
	GetAuthenticatedUser,
	GetClinicUnit,
} from '../../service/firebaseService';

const FILE = 'FILE://LOGIN';
// const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
const REFRESH_TOKEN_EXPIRED = 'RFERSH_TOKEN_EXPIRED';

const generateActionType = (type) => `${FILE}/${type}`;

const xPlatForm = { 'x-platform': 'web' };

export const errorTypes = {
	email: {
		type: 'email',
		message: 'Please provide a valid email',
	},
	password: {
		type: 'password',
		message: 'Please provide your password',
	},
	both: {
		type: 'both',
		message: 'Invalid Credentials',
	},
};

const FETCH_USER = generateActionType('FETCH_USER');
const AUTHENTICATE = generateActionType('AUTHENTICATE');
const SUCCESSFULLY_AUTHENTICATED_USER = generateActionType('SUCCESSFULLY_AUTHENTICATED_USER');
const FAILED_TO_AUTHENTICATE_USER = generateActionType('FAILED_TO_AUTHENTICATE_USER');
const CLEAR_LOGIN_ERROR = generateActionType('CLEAR_LOGIN_ERROR');
export const APP_LOGOUT = generateActionType('APP_LOGOUT');
const DISPLAY_ERROR = generateActionType('DISPLAY_ERROR');
const SAVE_PROFILE_DETAILS = generateActionType('SAVE_PROFILE_DETAILS');
const NEW_PASSWORD_CHALLENGE = generateActionType('NEW_PASSWORD_CHALLENGE');
const NEED_TO_LOGIN_AGAIN = generateActionType('NEED_TO_LOGIN_AGAIN');
const SAVE_MAIL_FOR_RESETTING_PASSWORD = generateActionType('SAVE_MAIL_FOR_RESETTING_PASSWORD');
const ERROR_IN_WHILE_RESETTING_PASSWORD = generateActionType('ERROR_IN_WHILE_RESETTING_PASSWORD');
const SENDING_RESET_LINK = generateActionType('SENDING_RESET_LINK');
const RESET_LINK_HAS_BEEN_SENT = generateActionType('RESET_LINK_HAS_BEEN_SENT');
const UN_MOUNT = generateActionType('UN_MOUNT');
const FETCH_NOTIFICATIONS = generateActionType('FETCH_NOTIFICATIONS');
const SAVE_NOTIFICATIONS = generateActionType('SAVE_NOTIFICATIONS');
const RESET_NOTIFICATIONS_COUNT = generateActionType('RESET_NOTIFICATIONS_COUNT');
const SAVE_FCM_TOKEN = generateActionType('SAVE_FCM_TOKEN');
const SHOW_LOGOUT_LOADER = generateActionType('SHOW_LOGOUT_LOADER');
const MARK_ALL_AS_READ = generateActionType('MARK_ALL_AS_READ');

function authenticatingPage(authenticating, authenticated) {
	return {
		type: AUTHENTICATE,
		authenticating,
		authenticated,
	};
}

function fetchUser(fetching) {
	return {
		type: FETCH_USER,
		fetching,
	};
}

export function saveUserDetails(userDetails) {
	return {
		type: SUCCESSFULLY_AUTHENTICATED_USER,
		userDetails,
	};
}

function userLoginFailed(error) {
	return {
		type: FAILED_TO_AUTHENTICATE_USER,
		error,
	};
}

export function clearError() {
	return { type: CLEAR_LOGIN_ERROR };
}
export function gotoLoginAgain() {
	return { type: NEED_TO_LOGIN_AGAIN };
}

export function logout() {
	localStorage.clear();
	return { type: APP_LOGOUT };
}

export function showError(message, errorType) {
	return { type: DISPLAY_ERROR, message, errorType };
}

function saveUserProfile(profileDetails) {
	return {
		type: SAVE_PROFILE_DETAILS,
		profileDetails,
	};
}

export function getLocalStorageValue(key) {
	if (!key) return null;

	return localStorage.getItem(key);
}

export function getLocalStorageValueAsJSON(key) {
	if (!key) return null;

	return JSON.parse(localStorage.getItem(key));
}

export function saveToLocalStorage(data = {}) {
	for (const [key, value] of Object.entries(data)) {
		localStorage.setItem(key, value);
	}
}

export function saveToLocalStorageAsJSONString(key, value) {
	console.log(key, value);
	localStorage.setItem(key, JSON.stringify(value));
}

export function deleteFromLocalStorage(key) {
	if (!key) return null;

	localStorage.removeItem(key);
}

function clearFromLocalStorage() {
	localStorage.clear();
}

export function saveEmail(email) {
	return { type: SAVE_MAIL_FOR_RESETTING_PASSWORD, email };
}

export function resetEmailError(config) {
	return { type: ERROR_IN_WHILE_RESETTING_PASSWORD, config };
}

export function unMount() {
	return { type: UN_MOUNT };
}

function fetchingNotifications(fetching) {
	return { type: FETCH_NOTIFICATIONS, fetching };
}

function savingNotifications(data) {
	return { type: SAVE_NOTIFICATIONS, data };
}

export function resetNotificationCount() {
	return { type: RESET_NOTIFICATIONS_COUNT };
}

export function resetReadStatus() {
	return { type: MARK_ALL_AS_READ };
}

export function isUnauthorized(dispatch, error) {
	const { response: { status, data = {} } = {} } = error;
	const msg = [REFRESH_TOKEN_EXPIRED, 'REFRESH_TOKEN_EXPIRED', 'Refresh token expired'];
	// IN CASE OF API ERROR FROM AXIOS
	if (status === 400 && msg.includes(data.message)) {
		dispatch(logout());
		return true;
	}
	return false;
}

async function getProfile() {
	const profileConfig = { url: 'users' };
	const response = await GET(profileConfig);
	return response;
}

export async function getClinicUnit() {
	const response = await GetClinicUnit();
	return response;
}

export async function registerUserDevice({ data = {} }) {
	const config = { url: 'users/devices', data, headers: xPlatForm };
	const response = await POST(config);
	return response;
}

export async function unregisterUserDevice({ data = {} }) {
	const config = { url: 'accounts/signout', data, headers: xPlatForm };
	const response = await POST(config);
	return response;
}

async function fetchNotifications(args) {
	const notifyConfig = { ...args };
	const response = await GET(notifyConfig);
	return response;
}

export function preLogout() {
	return async function (dispatch) {
		try {
			dispatch({ type: SHOW_LOGOUT_LOADER });
			// if (getLocalStorageValue(UID))
			// 	await unregisterUserDevice({ data: { deviceId: getLocalStorageValue(UID) } });
			dispatch(logout());
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			console.log('----- preLogout FUNCTION ERROR -------- ', error);
		}
	};
}

export async function fileDownload(awsKey) {
	if (awsKey === null && awsKey === '') return '';
	const awsConfig = { url: 'users/avatar', headers: { Key: awsKey } };
	const { data = {} } = await GET(awsConfig);
	return data.path;
}

export function getNotifications(args, inResetMode) {
	return async function (dispatch) {
		try {
			const notifyConfig = { ...args, url: `/notifications` };

			if (inResetMode) {
				dispatch(resetNotificationCount());
				await fetchNotifications(notifyConfig);
				return;
			}

			dispatch(fetchingNotifications(true));
			const { data = {} } = await fetchNotifications(notifyConfig);
			dispatch(savingNotifications(data));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch(fetchingNotifications(false));
			console.log('----- getNotifications FUNCTION ERROR -------- ', error);
		}
	};
}

export function getUserProfile() {
	const auth = { authenticated: true, authenticating: true };

	return async function (dispatch) {
		try {
			dispatch(authenticatingPage(auth.authenticating, !auth.authenticated));
			const { data = {} } = await getProfile();
			data.userDetails = { name: 'My Admin' };
			dispatch(saveUserProfile({ ...data, name: 'My Admin' }));
			dispatch(authenticatingPage(!auth.authenticating, auth.authenticated));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			console.log('----- getUserProfile FUNCTION ERROR -------- ', error);
		}
	};
}

export function authenticateUser(credentials) {
	// const postConfig = { data: credentials, url: 'account/signin' };
	// const postConfig = { data: credentials, url: 'accounts/signin' };
	return async function (dispatch) {
		try {
			console.log('I AM ON REFRESH AUTH');
			dispatch(fetchUser(true));
			const res = await AuthenticateUser(credentials);
			const authUser = await GetAuthenticatedUser('Users', res.user.uid);
			console.log('Res', authUser);
			const { data = { name: 'Clinic Admin', role: Roles.clinicAdmin, clinicID: res.user.uid } } =
				authUser;
			// const data = { name: 'Clinic Admin', role: Roles.clinicAdmin, clinicId: res.user.uid };
			console.log('RES', data);
			// const { data = {} } = await POST(postConfig);
			// if (Object.keys(data).length === 0) {
			// 	dispatch(userLoginFailed(data));
			// 	return;
			// }
			if (data.isActive === false) {
				dispatch(fetchUser(false));
				clearFromLocalStorage();
				dispatch(userLoginFailed({}));
				dispatch(showError(errorTypes.both.message, errorTypes.both.type));
			} else {
				// const { token, refreshToken = 'NO REFRESH TOKEN YET' } = data;
				saveToLocalStorage({
					[APP_TOKEN]: res.user.refreshToken,
					[REFRESH_TOKEN]: res.user.refreshToken,
				});

				// if (data.challengeName === NEW_PASSWORD_REQUIRED && data.session) {
				// 	dispatch({ type: NEW_PASSWORD_CHALLENGE, data: { ...credentials, ...data } });
				// 	return;
				// }

				// const { accessToken, refreshToken = 'NO REFRESH TOKEN YET' } = data;
				// if (data.role !== Roles.weCareAdmin) {
				// 	saveToLocalStorageAsJSONString('clinicId', data.clinicID || null);
				// }
				saveToLocalStorageAsJSONString('userDetails', data);
				dispatch(saveUserDetails({ ...data }));
			}
		} catch (error) {
			// const { response = {} } = error;
			// const { data = {}, status } = response;
			console.log('Error on lohgin', error);
			dispatch(fetchUser(false));
			clearFromLocalStorage();
			dispatch(userLoginFailed({}));
			dispatch(showError(errorTypes.both.message, errorTypes.both.type));
		}
	};
}

export function sendResetLinkViaMail({ email }) {
	return async function (dispatch) {
		try {
			const forgotConfig = { url: 'accounts/forgot-password', data: { email } };
			dispatch({ type: SENDING_RESET_LINK, fetching: true });
			await POST(forgotConfig);
			dispatch({ type: SENDING_RESET_LINK, fetching: false });
			setTimeout(() => {
				dispatch({ type: RESET_LINK_HAS_BEEN_SENT, redirect: true });
			}, 1500);
			dispatch(showSnackbar('Reset link has been sent to your email', snackBarErrorTypes.success));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch(showSnackbar(extractMessageFrom(error), snackBarErrorTypes.error));
			dispatch({ type: SENDING_RESET_LINK, fetching: false });
			console.log('----- sendResetLinkViaMail FUNCTION ERROR -------- ', error);
		}
	};
}

export function saveFcmToken(token) {
	return { type: SAVE_FCM_TOKEN, token };
}

const getInitialState = () => ({
	isLoggedIn: !!getLocalStorageValue(APP_TOKEN),
	authenticating: false,
	authenticated: false,
	fetchingUser: false,
	userDetails: {
		fcmToken: '',
		isANewUser: false,
		showSetPasswordScreen: false,
		showLoader: false,
	},
	userNotifications: {
		fetching: true,
		data: {},
	},
	error: {
		hasError: false,
		errorType: '',
		message: '',
	},
	forgotPassword: {
		email: '',
		waitingForApiResponse: false,
		redirect: false,
		error: {
			isVisible: false,
			message: '',
		},
	},
});

function loginReducer(state = getInitialState(), action) {
	switch (action.type) {
		case MARK_ALL_AS_READ: {
			const { data: { results = [] } = {} } = state.userNotifications;

			return {
				...state,
				userNotifications: {
					...state.userNotifications,
					data: {
						...state.userNotifications.data,
						results: results.map((result) => ({ ...result, isRead: true })),
					},
				},
			};
		}
		case SAVE_FCM_TOKEN:
			return {
				...state,
				userDetails: {
					...state.userDetails,
					fcmToken: action.token,
				},
			};
		case RESET_NOTIFICATIONS_COUNT:
			return {
				...state,
				userNotifications: {
					...state.userNotifications,
					data: {
						...state.userNotifications.data,
						unreadCount: 0,
					},
				},
			};
		case FETCH_NOTIFICATIONS:
			return {
				...state,
				userNotifications: {
					...state.userNotifications,
					fetching: action.fetching,
				},
			};
		case SAVE_NOTIFICATIONS:
			return {
				...state,
				userNotifications: {
					...state.userNotifications,
					fetching: false,
					data: action.data,
				},
			};
		case UN_MOUNT:
			return {
				...state,
				forgotPassword: {
					email: '',
					waitingForApiResponse: false,
					redirect: false,
					error: {
						isVisible: false,
						message: '',
					},
				},
			};
		case RESET_LINK_HAS_BEEN_SENT:
			return {
				...state,
				forgotPassword: {
					...state.forgotPassword,
					redirect: action.redirect,
				},
			};
		case SENDING_RESET_LINK:
			return {
				...state,
				forgotPassword: {
					...state.forgotPassword,
					waitingForApiResponse: action.fetching,
				},
			};
		case ERROR_IN_WHILE_RESETTING_PASSWORD: {
			return {
				...state,
				forgotPassword: {
					...state.forgotPassword,
					error: {
						isVisible: action.config.show,
						message: action.config.message,
					},
				},
			};
		}
		case SAVE_MAIL_FOR_RESETTING_PASSWORD:
			return {
				...state,
				forgotPassword: {
					...state.forgotPassword,
					email: action.email,
				},
			};
		case FETCH_USER:
			return {
				...state,
				fetchingUser: action.fetching,
			};
		case AUTHENTICATE:
			return {
				...state,
				authenticating: action.authenticating,
				authenticated: action.authenticated,
			};
		case SUCCESSFULLY_AUTHENTICATED_USER:
			return {
				...state,
				isLoggedIn: true,
				userDetails: {
					...action.userDetails,
					isANewUser: false,
					showSetPasswordScreen: false,
				},
			};
		case FAILED_TO_AUTHENTICATE_USER: {
			const { message = 'Invalid credentials' } = action.error;

			return {
				...state,
				authenticating: false,
				error: {
					hasError: true,
					message,
				},
			};
		}
		case CLEAR_LOGIN_ERROR:
			return {
				...state,
				error: {
					hasError: false,
					message: '',
				},
			};
		case DISPLAY_ERROR:
			return {
				...state,
				authenticating: false,
				error: {
					hasError: true,
					errorType: action.errorType,
					message: action.message,
				},
			};
		case SAVE_PROFILE_DETAILS:
			return {
				...state,
				userDetails: {
					...state.userDetails,
					...action.profileDetails,
				},
			};
		case NEW_PASSWORD_CHALLENGE: {
			return {
				...state,
				userDetails: {
					...action.data,
					isANewUser: true,
					showSetPasswordScreen: true,
				},
			};
		}
		case NEED_TO_LOGIN_AGAIN: {
			return {
				...state,
				fetchingUser: false,
				userDetails: {
					...action.data,
					isANewUser: false,
					showSetPasswordScreen: false,
				},
			};
		}
		case SHOW_LOGOUT_LOADER:
			return {
				...state,
				userDetails: {
					...state.userDetails,
					showLoader: true,
				},
			};
		case APP_LOGOUT:
			localStorage.clear();
			return true;

		default:
			return state;
	}
}

export default loginReducer;
