import React from 'react';
import { Toast, Button, Icon } from '../stories';

const ToastMessage = (props) => {
	const { openToast = false, closeToast, messageType = 'Success', content = '' } = props;
	const handleMessageType = (messageType) => {
		switch (messageType) {
			case 'Success':
				return <Icon iconName="icon-icon-tick-single" />;
			case 'Error':
				return <Icon iconName="icon-close" />;
			case 'Warning':
				return <Icon iconName="icon-warning" />;
			default:
				return '';
		}
	};
	return (
		<Toast iconToast ToastBorder show={openToast} messageType={messageType} Right="20px" Top="20px">
			{handleMessageType(messageType)}
			{content}
			<Button onClick={closeToast}>
				<Icon iconName="icon-close" />
			</Button>
		</Toast>
	);
};

export default ToastMessage;

ToastMessage.defaultProps = {
	closeToast: () => {},
};
