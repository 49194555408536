import { lazy } from 'react';

import { Roles } from '../helpers/Constants';
import layoutProps from './route.params';

const Profile = lazy(() => import('../pages/Profile'));
const ClinicalNotes = lazy(() => import('../pages/ClinicalNotes'));
const Patients = lazy(() => import('../pages/PatientList'));
const ClinicManagement = lazy(() => import('../pages/ClinicManagement'));
const ClinicViewDetails = lazy(() => import('../pages/ClinicManagement/Controller/ViewController'));
const PatientManagement = lazy(() => import('../pages/PatientManagement'));
const PatientViewDetails = lazy(() =>
	import('../pages/PatientManagement/Controller/ViewController')
);
const PatientInvitation = lazy(() => import('../pages/PatientInvitation'));
const HealthCard = lazy(() => import('../pages/HealthCard'));
const HealthCardView = lazy(() => import('../pages/HealthCard/Controller/ViewController'));
const PatientDetails = lazy(() => import('../pages/PatientList/Components/PatientDetails'));

const Unauthorized = lazy(() => import('../pages/Unauthorized'));
const DietManagement = lazy(() => import('../pages/DietManagement'));

const PatientVault = lazy(() => import('../pages/PatientVault'));
const AbnormalityReport = lazy(() => import('../pages/AbnormalityReport'));

const NewAbnormalityReport = lazy(() =>
	import('../pages/AbnormalityReport/createAbnormalityReport')
);

export default [
	{
		exact: true,
		name: 'Unauthorized',
		path: '/unauthorized',
		component: Unauthorized,
		visibleTo: [Roles.coach, Roles.doctor, Roles.weCareAdmin, Roles.clinicAdmin],
	},
	{
		exact: true,
		name: 'PatientList',
		path: '/patients',
		component: Patients,
		visibleTo: [Roles.coach, Roles.doctor, Roles.weCareAdmin, Roles.clinicAdmin],
	},

	{
		exact: true,
		name: 'Profile',
		path: '/profile',
		component: Profile,
		visibleTo: [Roles.coach, Roles.doctor, Roles.weCareAdmin, Roles.clinicAdmin],
	},
	{
		exact: true,
		name: 'ClinicalNotes',
		path: '/clinical-notes/:id',
		component: ClinicalNotes,
	},

	{
		exact: true,
		name: 'Patient detail',
		path: '/patient/:patientId',
		component: PatientDetails,
		visibleTo: [Roles.coach, Roles.doctor],
		layoutProps: layoutProps.patientManagement,
	},
	{
		exact: true,
		name: 'PatientInvitation',
		path: '/patient-invitation',
		component: PatientInvitation,
		visibleTo: [Roles.clinicAdmin, Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'ClinicManagement',
		path: '/clinic-management',
		component: ClinicManagement,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'ClinicViewDetails',
		path: '/clinic-management/:id',
		component: ClinicViewDetails,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'DietManagement',
		path: '/diets',
		component: DietManagement,
		visibleTo: [Roles.coach, Roles.doctor, Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'PatientManagement',
		path: '/patient-management',
		component: PatientManagement,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'PatientViewDetails',
		path: '/patient-management/:id',
		component: PatientViewDetails,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'HealthCard',
		path: '/health-card',
		component: HealthCard,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'HealthCardView',
		path: '/health-card/:categoryID/:id',
		component: HealthCardView,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'Patient vault',
		path: '/patientvault',
		component: PatientVault,
		visibleTo: [Roles.doctor, Roles.coach, Roles.clinicAdmin, Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'Abnormality report',
		path: '/patient-management/:patientId/abnormality-report',
		component: AbnormalityReport,
		visibleTo: [Roles.doctor, Roles.coach, Roles.clinicAdmin, Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'New abnormality report',
		path: '/patient-management/:patientId/new-abnormality-report',
		component: NewAbnormalityReport,
		visibleTo: [Roles.doctor, Roles.coach, Roles.clinicAdmin, Roles.weCareAdmin],
	},
];
