import styled from 'styled-components';

const Bredcrumb = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	${({ responsiveBredcrumb }) =>
		responsiveBredcrumb &&
		`
		@media screen and (max-width: 767px) {
			padding-left:37px;
		}
	`}
`;

export default Bredcrumb;
