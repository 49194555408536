/* eslint-disable camelcase */
import ExerciseIconSVG from '../stories/assets/images/icons/card-exercise-icon.svg';
import DietIconSVG from '../stories/assets/images/icons/card-diet-icon.svg';
import MedicationIconSVG from '../stories/assets/images/icons/card-medication-icon.svg';
import FluidIntakeIconSVG from '../stories/assets/images/icons/card-fluid-icon.svg';
import MoodIconSVG from '../stories/assets/images/icons/card-smile-icon.svg';
import SleepIconSVG from '../stories/assets/images/icons/card-sleep-icon.svg';

import ExerciseHeaderIconSVG from '../stories/assets/images/icons/activity-exercise-icon.svg';
import DietHeaderIconSVG from '../stories/assets/images/icons/activity-diet-icon.svg';
import MedicationHeaderIconSVG from '../stories/assets/images/icons/activity-medication-icon.svg';
import FluidHeaderIconSVG from '../stories/assets/images/icons/activity-fluid-icon.svg';
import MoodHeaderIconSVG from '../stories/assets/images/icons/activity-mood-icon.svg';
import SleepHeaderIconSVG from '../stories/assets/images/icons/activity-sleep-icon.svg';

import IndianFlag from '../assets/images/Indian-Flag.png';
import MyanmarFlag from '../assets/images/Myanmar-Flag.png';
import EthiopiaFlag from '../assets/images/Ethiopia-Flag.png';
import NigeriaFlag from '../assets/images/Nigeria-Flag.png';

// import Head from '../stories/assets/images/body_parts/head.svg';
// import Neck from '../stories/assets/images/body_parts/neck.svg';
// import Chest from '../stories/assets/images/body_parts/chest.svg';
// import Stomach from '../stories/assets/images/body_parts/stomach.svg';
// import LeftArm from '../stories/assets/images/body_parts/left_arm.svg';
// import RightArm from '../stories/assets/images/body_parts/right_arm.svg';
// import LeftForeArm from '../stories/assets/images/body_parts/left_fore_arm.svg';
// import RightForeArm from '../stories/assets/images/body_parts/right_fore_arm.svg';
// import LeftWrist from '../stories/assets/images/body_parts/left_wrist.svg';
// import RightWrist from '../stories/assets/images/body_parts/right_wrist.svg';

import B_Head from '../stories/assets/images/body_parts/head_back.png';
import F_Head from '../stories/assets/images/body_parts/head_front.png';
import B_Neck from '../stories/assets/images/body_parts/back_neck.png';
import F_Neck from '../stories/assets/images/body_parts/front_neck.png';
import Buttocks from '../stories/assets/images/body_parts/buttocks.png';
import Chest from '../stories/assets/images/body_parts/chest.svg';
import Hip from '../stories/assets/images/body_parts/hip.png';
import LeftAnkleFoot from '../stories/assets/images/body_parts/left_angle_foot.png';
import LeftArm from '../stories/assets/images/body_parts/left_arm.png';
import LeftElbow from '../stories/assets/images/body_parts/left_elbow.png';
import LeftForeArm from '../stories/assets/images/body_parts/left_forearm.png';
import LeftKnee from '../stories/assets/images/body_parts/left_knee.png';
import LeftLeg from '../stories/assets/images/body_parts/left_leg.png';
import LeftLegThigh from '../stories/assets/images/body_parts/left_leg_thigh.png';
import LeftWristHand from '../stories/assets/images/body_parts/left_wrist_hand.png';
import LowerAbdomen from '../stories/assets/images/body_parts/lower_abdomen.png';
import LowerBack from '../stories/assets/images/body_parts/lower_back.png';
import RightArm from '../stories/assets/images/body_parts/right_am.png';
import RightAnkleFoot from '../stories/assets/images/body_parts/right_ankle_foot.png';
import RightElbow from '../stories/assets/images/body_parts/right_elbow.png';
import RightForeArm from '../stories/assets/images/body_parts/right_forearm.png';
import RightKnee from '../stories/assets/images/body_parts/right_knee.png';
import RightLeg from '../stories/assets/images/body_parts/right_leg.png';
import RightThigh from '../stories/assets/images/body_parts/right_thigh.png';
import RightWristHand from '../stories/assets/images/body_parts/right_wrist_hand.png';
import ShoulderPain from '../stories/assets/images/body_parts/shoulder_pain.png';
import Stomach from '../stories/assets/images/body_parts/stomach.png';
import UpperBack from '../stories/assets/images/body_parts/upper_back.png';

export const MILLISECONDS_IN_A_DAY = 86400000;

export const MAXIMUM_UPLOAD_FILE_SIZE = 5000000;

export const MAXIMUM_CHARACTERS_ALLOWED = 10000;

export const MAXIMUM_MESSAGE = 30;

export const PER_PAGE = 10;

export const MONTHS_IN_A_YEAR = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export const Pattern = {
	EMAIL:
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	// NAME: /^[a-z\d.'\s]+$/,
	NAME: /^.*/,
};

export const dateSeparatorConfig = {
	chat: 'T_D_DMY', // Example: 03:15 PM/YESTERDAY/01 Apr 2020
	hh_mm_a: 'hh_mm_a', // Example: 03:15 PM
	L_D_M_Y: 'LDMY', // Example: 1 April 2020
	l_d_m_y: 'ldmy', // Example: 01 Apr 2020
	hh_mm: 'hh_mm', // Example: 03:15
	default: 'default', // Example: 01 Apr 2020, 03:15 PM
	defaultDate: 'defaultDate', // Example: 01 Apr 2020
	defaultTime: 'defaultTime', // Example: 03:15 PM
	defaultRecentDate: 'defaultRecentDate', // Example: Today OR Yesterday OR 01 Apr 2020
	defaultRecentDatetime: 'defaultRecentDatetime', // Example: Today, 03:15 PM OR Yesterday, 03:15 PM OR 01 Apr 2020, 03:15 PM
	yyyy_mm_dd: 'yyyy_mm_dd', // Example: 2020-02-29
	d_m: 'd_m', // 05 Apr
};

export const APP_TOKEN = 'APP_TOKEN';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const UID = 'UID';

export const FCM_TOKEN = 'TOKEN';

export const Roles = {
	coach: 'Coach',
	doctor: 'Doctor',
	weCareAdmin: 'WeCareAdmin',
	clinicAdmin: 'ClinicAdmin',
	patient: 'Patient',
};

export const FileExtension = [
	'jpg',
	'jpeg',
	'bmp',
	'png',
	'docx',
	'doc',
	'pdf',
	'xls',
	'xlsx',
	'xlsm',
	'txt',
];
export const ImgExtension = ['jpg', 'jpeg', 'bmp', 'png'];
// Here, the "Coach" key has to be same as the "Roles.coach" (above) value
export const RolesFromApi = {
	doctor: 'doctor',
	clinicAdmin: 'clinic-admin',
	Coach: 'coach',
};

export const BP = 'BP';

export const NIL = '---';

export const PENDING_SIGN_UP = 'Pending sign-up';

export const vitalKeys = {
	systolic: 'SYSTOLIC',
	diastolic: 'DIASTOLIC',
	temperature: 'TEMPERATURE',
	heartRate: 'HEART_RATE',
	respiratoryRate: 'RESPIRATORY_RATE',
	oxygenSaturation: 'OXYGEN_SATURATION',
};

export const snackBarErrorTypes = {
	info: 'info',
	error: 'Error',
	success: 'Success',
	warning: 'Warning',
};

export const doctorLabel = 'Dr. ';

export default {
	patientVault: {
		details: '#details',
		health: '#healthvault',
		clinicalNotes: '#clinicalnotes',
		clinicalDocs: '#clinicaldocs',
		sleep: '#sleep',
		mood: '#mood',
		vitals: '#vitals',
		lab: '#labs',
		abnormality: '#abnormality',
		weights: '#weights',
		vitalDetails: '#vitaldetails',
		labDetails: '#labdetails',
		activityJournal: '#activityjournal',
		visitSummary: '#visitSummary',
	},
};

export const Activity = {
	EXERCISE: {
		label: 'Exercise',
		icon: ExerciseIconSVG,
		className: {},
		node: 'exercise',
		activityDate: 'activityDate',
		headerIcon: ExerciseHeaderIconSVG,
		title: 'Active',
		titles: 'Active',
		subTitle: 'days',
	},
	DIET: {
		label: 'Diet',
		icon: DietIconSVG,
		className: { dietCard: true },
		node: 'diet',
		activityDate: 'intakeDate',
		headerIcon: DietHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'on diet plan',
	},
	MEDICATIONS: {
		label: 'Medication',
		icon: MedicationIconSVG,
		className: { medicationCard: true },
		node: 'medication',
		activityDate: 'intakeDate',
		headerIcon: MedicationHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'on medication',
	},
	FLUID_INTAKE: {
		label: 'Water and fluid intake',
		icon: FluidIntakeIconSVG,
		className: { waterFluidCard: true },
		node: 'fluid',
		activityDate: 'intakeDate',
		headerIcon: FluidHeaderIconSVG,
		title: '',
		titles: '',
		subTitle: 'Glasses',
	},
	MOOD: {
		label: 'Moods',
		icon: MoodIconSVG,
		className: { moodsCard: true },
		node: 'moods',
		activityDate: 'date',
		headerIcon: MoodHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'logged',
	},
	SLEEP: {
		label: 'Sleep',
		icon: SleepIconSVG,
		className: { sleepCard: true },
		node: 'sleep',
		activityDate: 'date',
		headerIcon: SleepHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'logged',
	},
};

export const Time = {
	'MINS_60+': '1+ hours',
	MINS_15: '15 mins',
	MINS_45: '45 mins',
	MINS_30: '30 mins',
	MINS_60: '1 hour',
};

export const Intensity = {
	HARD: { text: 'Hard', class: { Hard: true } },
	MODERATE: { text: 'Moderate', class: { Moderate: true } },
	EASY: { text: 'Easy', class: { Easy: true } },
};

export const ActivityList = {
	OTHERS: 'Others',
	WALK_RUN: 'Walk/Run',
	WORKOUT: 'Workout',
	YOGA: 'Yoga',
	SWIMMING: 'Swimming',
	GAME: 'Game',
	MISC: 'Misc',
	CYCLING: 'Cycling',
	INACTIVE: 'Inactive',
};

export const Days = {
	SUN: 'Sun',
	MON: 'Mon',
	TUE: 'Tue',
	WED: 'Wed',
	THU: 'Thu',
	FRI: 'Fri',
	SAT: 'Sat',
};

export const CountryList = [
	{
		code: '+91',
		flag: IndianFlag,
	},
	{
		code: '+95',
		flag: MyanmarFlag,
	},
	{
		code: '+251',
		flag: EthiopiaFlag,
	},
	{
		code: '+234',
		flag: NigeriaFlag,
	},
];

export const BloodGroupList = ['A+ve', 'A-ve', 'B+ve', 'B-ve', 'O+ve', 'O-ve', 'AB+ve', 'AB-ve'];
export const SecondaryHealthConditionList = [
	'Hypertension',
	'Arthritis',
	'Asthma',
	'Osteoporosis',
	'Not applicable',
];

export const DiabeticList = [
	'Type 1 diabetes',
	'Type 2 diabetes',
	'Gestational diabetes',
	'Not sure what type',
	"No , I'm not",
	"I'm not sure",
	"No , I'm not",
];

export const HobbiesList = [
	'Travel',
	'Art',
	'Cooking',
	'Reading',
	'Sports',
	'Singing',
	'Dancing',
	'Not applicable',
];

export const PhysicallyActiveList = ['Active', 'Bedridden', 'Moderate', 'Sedentary'];

export const AllergiesList = ['Food', 'Dust', 'Environment', 'Medicine'];

export const AlcoholHabitList = ['Yes', 'No', 'Occasionally'];

export const LabReportList = [
	{
		category: 'GLUCOSE',
		name: 'Glucose',
		tests: [
			{
				lookupKey: 'URINE_SUGAR',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'HBA1C',
				unitType: '%',
				description: '',
			},
			{
				lookupKey: 'GTT',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'PPBS',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'RBS',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'FBS',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'GRBS',
				unitType: 'mg/dL',
				description: '',
			},
		],
	},
	{
		category: 'CHOLESTEROL',
		name: 'Cholesterol',
		tests: [
			{
				lookupKey: 'VLDL',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'TOTAL_CHOLESTEROL',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'TRIGLYCERIDES',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'HDL',
				unitType: 'mg/dL',
				description: '',
			},
			{
				lookupKey: 'LDL',
				unitType: 'mg/dL',
				description: '',
			},
		],
	},
	{
		category: 'KFT',
		name: 'KFT',
		tests: [
			{
				lookupKey: 'SERUM_URIC_ACID',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'SERUM_CREATININE',
				unitType: 'µmol/L',
				description: '',
			},
			{
				lookupKey: 'BLOOD_UREA',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'TFT',
		name: 'TFT',
		tests: [
			{
				lookupKey: 'FREE_T4',
				unitType: 'pmol/L',
				description: '',
			},
			{
				lookupKey: 'FREE_T3',
				unitType: 'ng/L',
				description: '',
			},
			{
				lookupKey: 'T4',
				unitType: 'nmol/L',
				description: '',
			},
			{
				lookupKey: 'T3',
				unitType: 'nmol/L',
				description: '',
			},
			{
				lookupKey: 'TSH',
				unitType: 'µIU/mL',
				description: '',
			},
		],
	},
	{
		category: 'LFT',
		name: 'LFT',
		tests: [
			{
				lookupKey: 'ALKALINE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'GAMMA_GT',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'ALT',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'AST',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'AG_RATIO',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'UNCONJ_BILIRUBIN',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'CONJ_BILIRUBIN',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'TOTAL_BILIRUBIN',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'HEMATOLOGY',
		name: 'Hematology',
		tests: [
			{
				lookupKey: 'ESR',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'PLATELETS',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'TC',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'TD',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'HB',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'URINE',
		name: 'Urine',
		tests: [
			{
				lookupKey: 'URINE_PREGNANCY',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'URINE_CULTURE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'URINE_ROUTINE',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'STOOL',
		name: 'Stool',
		tests: [
			{
				lookupKey: 'STOOL_OCCULT',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'STOOL_CULTURE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'STOOL_ROUTINE',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'SPUTUM',
		name: 'Sputum',
		tests: [
			{
				lookupKey: 'SPUTUM_CULTURE',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'CSF',
		name: 'CSF',
		tests: [
			{
				lookupKey: 'CSF_CULTURE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'CSF_ROUTINE',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'ASCITIC',
		name: 'Ascitic fluid for counts',
		tests: [
			{
				lookupKey: 'ASCITIC_MALIGNANT',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'ASCITIC_CULTURE',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'PLEURAL',
		name: 'Pleural',
		tests: [
			{
				lookupKey: 'PLEURAL_MALIGNANT',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'PLEURAL_CULTURE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'PLEURAL_COUNTS',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'PUS',
		name: 'Pus',
		tests: [
			{
				lookupKey: 'PUS_CULTURE',
				unitType: '',
				description: '',
			},
			{
				lookupKey: 'GRAMS_STAIN',
				unitType: '',
				description: '',
			},
		],
	},
	{
		category: 'OTHERS',
		name: 'Others',
		tests: [
			{
				lookupKey: 'OTHERS',
				unitType: '',
				description: '',
			},
		],
	},
];

export const VitalNormalcy = {
	SYSTOLIC: {
		min: 90,
		max: 130,
		name: 'Systolic',
	},

	DIASTOLIC: {
		min: 60,
		max: 90,
		name: 'Diastolic',
	},

	HEART_RATE: {
		min: 50,
		max: 100,
		name: 'Heart rate',
	},

	TEMPERATURE: {
		fahrenheit: { min: 91.4, max: 100 },
		celsius: { min: 33.0, max: 37.7 },
		name: 'Temperature',
	},
	OXYGEN_SATURATION: {
		min: 98,
		max: 100,
		name: 'Oxygen saturation',
	},
	RESPIRATORY_RATE: {
		min: 10,
		max: 25,
		name: 'Respiratory rate',
	},
};

export const ClinicUnits = {
	height: 'cm',
	weight: 'kg',
	waist: 'cm',
	hip: 'cm',
	HEART_RATE: 'bpm',
	SYSTOLIC: 'mmHg',
	DIASTOLIC: 'mmHg',
	RESPIRATORY_RATE: 'mt',
	OXYGEN_SATURATION: '%',
	URINE_SUGAR: 'mg/dL',
	HBA1C: '%',
	GTT: 'mg/dL',
	PPBS: 'mg/dL',
	RBS: 'mg/dL',
	FBS: 'mg/dL',
	GRBS: 'mg/dL',
	VLDL: 'mg/dL',
	TOTAL_CHOLESTEROL: 'mg/dL',
	TRIGLYCERIDES: 'mg/dL',
	HDL: 'mg/dL',
	LDL: 'mg/dL',
	SERUM_URIC_ACID: '',
	SERUM_CREATININE: 'µmol/L',
	BLOOD_UREA: '',
	FREE_T4: 'pmol/L',
	FREE_T3: 'ng/L',
	T4: 'nmol/L',
	T3: 'nmol/L',
	TSH: 'µIU/mL',
	ALKALINE: '',
	GAMMA_GT: '',
	ALT: '',
	AST: '',
	AG_RATIO: '',
	UNCONJ_BILIRUBIN: '',
	CONJ_BILIRUBIN: '',
	TOTAL_BILIRUBIN: '',
	ESR: '',
	PLATELETS: '',
	TC: '',
	TD: '',
	HB: '',
	URINE_PREGNANCY: '',
	URINE_CULTURE: '',
	URINE_ROUTINE: '',
	STOOL_OCCULT: '',
	STOOL_CULTURE: '',
	STOOL_ROUTINE: '',
	SPUTUM_CULTURE: '',
	CSF_CULTURE: '',
	CSF_ROUTINE: '',
	ASCITIC_MALIGNANT: '',
	ASCITIC_CULTURE: '',
	PLEURAL_MALIGNANT: '',
	PLEURAL_CULTURE: '',
	PLEURAL_COUNTS: '',
	PUS_CULTURE: '',
	GRAMS_STAIN: '',
};

export const lookupKeys = {
	general: 'General',
	front: 'Front',
	back: 'Back',
	f_: 'Front',
	b_: 'Back',
	head: 'Head',
	neck: 'Neck',
	chest: 'Chest',
	stomach: 'Stomach',
	left_arm: 'Left arm',
	right_arm: 'Right arm',
	left_forearm: 'Left forearm',
	right_forearm: 'Right forearm',
	left_wrist: 'Left wrist & hand',
	right_wrist: 'Right wrist & hand',
	mild: 'Mild',
	moderate: 'Moderate',
	serve: 'Severe',
	appetite: 'Appetite',
	body_pain: 'Body pain',
	breathlessness: 'Breathlessness',
	cold: 'Cold',
	constipation: 'Constipation',
	cough: 'Cough',
	diarrhea: 'Diarrhea',
	discolouration: 'Discolouration',
	dizziness: 'Dizziness',
	drowsiness: 'Drowsiness',
	ear_problems: 'Ear problems',
	eye_problems: 'Eye problems',
	fever: 'Fever',
	giddiness: 'Giddiness',
	headache: 'Headache',
	increased_thirst: 'Increased thirst',
	itching: 'Itching',
	loose_stools: 'Loose stools',
	movement_restriction: 'Movement restriction',
	nausea: 'Nausea',
	numbness: 'Numbness',
	pain: 'Pain',
	swallowing_difficulty: 'Swallowing difficulty',
	swelling: 'Swelling',
	tingling: 'Tingling',
	toothache: 'Toothache',
	urinary_problem: 'Urinary problem',
	vomiting: 'Vomiting',
	weakness: 'Weakness',
	weight_loss: 'Weight loss',
	wound: 'Wound',
	multiple: 'Multiple',
	with_pus: 'With pus',
	with_fever: 'With fever',
	with_bleeding: 'With bleeding',
	single: 'Single',
	with_cough: 'With cough',
	with_loose_stools: 'With loose stools',
	with_loss_of_appetite: 'With loss of appetite',
	with_recurrent_infections: 'With recurrent infections',
	with_urinary_problem: 'With urinary problem',
	part: 'Part',
	left_side: 'Left side',
	generalized: 'Generalized',
	right_side: 'Right side',
	with_bloating_sensation: 'With bloating sensation',
	yellowish_fluid: 'Yellowish fluid',
	with_burning_in_stomach: 'With burning in stomach',
	food_particles: 'Food particles',
	blood_tinged: 'Blood tinged',
	black_tinged: 'Black tinged',
	after_food: 'After food',
	Puss_in_urine: 'Puss in urine',
	increased_frequency: 'Increased frequency',
	difficulty_in_passing: 'Difficulty in passing',
	burning_while_passing: 'Burning while passing',
	blood_in_urine: 'Blood in urine',
	with_redness_in_gums: 'With redness in gums',
	earlier_dental_procedure: 'Earlier dental procedure',
	multiple_teeth: 'Multiple teeth',
	one_tooth: 'One tooth',
	with_discolouration: 'With discolouration',
	with_numbness: 'With numbness',
	with_pain: 'With pain',
	with_loss_of_sensation: 'With loss of sensation',
	with_redness: 'With redness',
	for_all_food: 'For all food',
	with_neck_swelling: 'With neck swelling',
	for_solids_alone: 'For solids alone',
	with_burning_sensation: 'With burning sensation',
	with_throat_pain: 'With throat pain',
	for_liquids_alone: 'For liquids alone',
	with_weight_loss: 'With weight loss',
	catching: 'Catching',
	with_sweating: 'With sweating',
	throbbing: 'Throbbing',
	pricking: 'Pricking',
	burning: 'Burning',
	aching: 'Aching',
	with_tingling: 'With tingling',
	multiple_joints: 'Multiple joints',
	one_joint: 'One joint',
	with_swelling: 'With swelling',
	watery: 'Watery',
	mucus_present: 'Mucus present',
	formed_but_loose: 'Formed but loose',
	black_coloured: 'Black coloured',
	with_difficulty_in_breathing: 'With difficulty in breathing',
	overall_the_body: 'Overall the body',
	only_in_one_part: 'Only in one part',
	with_increased_urination: 'With increased urination',
	with_change_in_appetite: 'With change in appetite',
	on_one_side: 'On one side',
	continuous: 'Continuous',
	with_vision_problem: 'With vision problem',
	dull_aching: 'Dull aching',
	with_vomiting: 'With vomiting',
	after_fall_or_hit_on_head: 'After fall or hit on head',
	extreme_tiredness: 'Extreme tiredness',
	with_room_spinning: 'With room spinning',
	with_headache: 'With headache',
	present_only_at_a_particular_time: 'Present only at a particular time',
	comes_and_goes: 'Comes and goes',
	increasing: 'Increasing',
	itching_or_burning: 'Itching or burning',
	double_vision: 'Double vision',
	difficulty_in_reading: 'Difficulty in reading',
	watering: 'Watering',
	ringing_sound: 'Ringing sound',
	blocked_ear: 'Blocked ear',
	difficulty_in_hearing: 'Difficulty in hearing',
	ear_pain: 'Ear pain',
	puss_discharge: 'Puss discharge',
	with_giddiness: 'With giddiness',
	with_breathlessness: 'With breathlessness',
	unsteadiness: 'Unsteadiness',
	with_wound: 'With wound',
	multiple_times_a_day: 'Multiple times a day',
	all_the_time: 'All the time',
	cough_with_sputum: 'Cough with sputum',
	dry_cough: 'Dry cough',
	on_lying: 'On lying',
	associated_with_particular_food: 'Associated with particular food',
	associated_with_pain: 'Associated with pain',
	followed_by_blood_in_stool: 'Followed by blood in stool',
	foul_smelling: 'Foul smelling',
	thick: 'Thick',
	when_exposed_to_dust: 'When exposed to dust',
	on_walking: 'On walking',
	decreased: 'Decreased',
	increased: 'Increased',
	upper_back: 'Upper back',
	shoulder_pain: 'Shoulder pain',
	right_thigh: 'Right thigh',
	left_thigh: 'Left thigh',
	right_leg: 'Right leg',
	left_leg: 'Left leg',
	right_knee: 'Right knee',
	left_knee: 'Left knee',
	right_elbow: 'Right elbow',
	left_elbow: 'Left elbow',
	right_ankle: 'Right ankle',
	left_ankle: 'Left ankle',
	hip: 'Hip',
	buttocks: 'Buttocks',
};

export const BodySide = {
	general: 'GENERAL',
	front: 'F_',
	back: 'B_',
};

export const BodyParts = {
	f_head: {
		lookupKey: 'HEAD',
		icon: F_Head,
		name: 'Head',
	},
	b_head: {
		lookupKey: 'HEAD',
		icon: B_Head,
		name: 'Head',
	},
	f_neck: {
		lookupKey: 'NECK',
		icon: F_Neck,
		name: 'Neck',
	},
	b_neck: {
		lookupKey: 'NECK',
		icon: B_Neck,
		name: 'Neck',
	},

	f_chest: {
		lookupKey: 'CHEST',
		icon: Chest,
		name: 'Chest',
	},
	f_stomach: {
		lookupKey: 'STOMACH',
		icon: Stomach,
		name: 'Stomach',
	},
	f_left_arm: {
		lookupKey: 'LEFT_ARM',
		icon: LeftArm,
		name: 'Left arm',
	},
	b_left_arm: {
		lookupKey: 'LEFT_ARM',
		icon: LeftArm,
		name: 'Left arm',
	},
	f_right_arm: {
		lookupKey: 'RIGHT_ARM',
		icon: RightArm,
		name: 'Right arm',
	},
	b_right_arm: {
		lookupKey: 'RIGHT_ARM',
		icon: RightArm,
		name: 'Right arm',
	},
	f_left_forearm: {
		lookupKey: 'LEFT_FOREARM',
		icon: LeftForeArm,
		name: 'Left forearm ',
	},
	b_left_forearm: {
		lookupKey: 'LEFT_FOREARM',
		icon: LeftForeArm,
		name: 'Left forearm ',
	},
	f_right_forearm: {
		lookupKey: 'RIGHT_FOREARM',
		icon: RightForeArm,
		name: 'Right forearm ',
	},
	b_right_forearm: {
		lookupKey: 'RIGHT_FOREARM',
		icon: RightForeArm,
		name: 'Right forearm ',
	},
	f_left_wrist: {
		lookupKey: 'LEFT_WRIST',
		icon: LeftWristHand,
		name: 'Left wrist & hand ',
	},
	b_left_wrist: {
		lookupKey: 'LEFT_WRIST',
		icon: LeftWristHand,
		name: 'Left wrist & hand ',
	},
	f_right_wrist: {
		lookupKey: 'RIGHT_WRIST',
		icon: RightWristHand,
		name: 'Right wrist & hand ',
	},
	r_right_wrist: {
		lookupKey: 'RIGHT_WRIST',
		icon: RightWristHand,
		name: 'Right wrist & hand ',
	},
	b_buttocks: {
		lookupKey: 'BUTTOCKS',
		icon: Buttocks,
		name: 'Buttocks',
	},
	f_hip: {
		lookupKey: 'HIP',
		icon: Hip,
		name: 'Hip',
	},
	b_hip: {
		lookupKey: 'HIP',
		icon: Hip,
		name: 'Hip',
	},
	f_left_ankle: {
		lookupKey: 'LEFT_ANKLE',
		icon: LeftAnkleFoot,
		name: 'Left ankle & foot',
	},
	b_left_ankle: {
		lookupKey: 'LEFT_ANKLE',
		icon: LeftAnkleFoot,
		name: 'Left ankle & foot',
	},
	f_left_elbow: {
		lookupKey: 'LEFT_ELBOW',
		icon: LeftElbow,
		name: 'Left elbow',
	},
	b_left_elbow: {
		lookupKey: 'LEFT_ELBOW',
		icon: LeftElbow,
		name: 'Left elbow',
	},
	b_left_knee: {
		lookupKey: 'LEFT_KNEE',
		icon: LeftKnee,
		name: 'Left knee',
	},
	f_left_knee: {
		lookupKey: 'LEFT_KNEE',
		icon: LeftKnee,
		name: 'Left knee',
	},
	f_left_leg: {
		lookupKey: 'LEFT_LEG',
		icon: LeftLeg,
		name: 'Left leg',
	},
	b_left_leg: {
		lookupKey: 'LEFT_LEG',
		icon: LeftLeg,
		name: 'Left leg',
	},
	f_left_thigh: {
		lookupKey: 'LEFT_THIGH',
		icon: LeftLegThigh,
		name: 'Left thigh',
	},
	b_left_thigh: {
		lookupKey: 'LEFT_THIGH',
		icon: LeftLegThigh,
		name: 'Left thigh',
	},
	f_lower_abdomen: {
		lookupKey: 'LOWER_ABDOMEN',
		icon: LowerAbdomen,
		name: 'Lower abdomen',
	},
	b_lower_back: {
		lookupKey: 'LOWER_BACK',
		icon: LowerBack,
		name: 'Lower back',
	},

	f_right_ankle: {
		lookupKey: 'RIGHT_ANKLE',
		icon: RightAnkleFoot,
		name: 'Right ankle & foot',
	},
	b_right_ankle: {
		lookupKey: 'RIGHT_ANKLE',
		icon: RightAnkleFoot,
		name: 'Right ankle & foot',
	},
	f_right_elbow: {
		lookupKey: 'RIGHT_ELBOW',
		icon: RightElbow,
		name: 'Right elbow',
	},
	b_right_elbow: {
		lookupKey: 'RIGHT_ELBOW',
		icon: RightElbow,
		name: 'Right elbow',
	},
	b_right_knee: {
		lookupKey: 'RIGHT_KNEE',
		icon: RightKnee,
		name: 'Right knee',
	},
	f_right_knee: {
		lookupKey: 'RIGHT_KNEE',
		icon: RightKnee,
		name: 'Right knee',
	},

	f_right_leg: {
		lookupKey: 'RIGHT_LEG',
		icon: RightLeg,
		name: 'Right leg',
	},
	b_right_leg: {
		lookupKey: 'RIGHT_LEG',
		icon: RightLeg,
		name: 'Right leg',
	},
	f_right_thigh: {
		lookupKey: 'RIGHT_THIGH',
		icon: RightThigh,
		name: 'Right thigh',
	},
	b_right_thigh: {
		lookupKey: 'RIGHT_THIGH',
		icon: RightThigh,
		name: 'Left thigh',
	},

	f_shoulder_pain: {
		lookupKey: 'SHOULDER_PAIN',
		icon: ShoulderPain,
		name: 'Shoulder pain',
	},
	b_shoulder_pain: {
		lookupKey: 'SHOULDER_PAIN',
		icon: ShoulderPain,
		name: 'Shoulder pain',
	},
	b_upper_back: {
		lookupKey: 'UPPER_BACK',
		icon: UpperBack,
		name: 'Upper back',
	},
};

export const VitalDependency = {
	fever: {
		body: null,
		vital: ['temperature'],
	},
	chest_pain: {
		body: 'chest',
		vital: ['blood pressure', 'heart rate', 'respiratory rate'],
	},
	pain: {
		body: null,
		vital: ['blood pressure', 'heart rate'],
	},
	breathlessness: {
		body: null,
		vital: ['respiratory rate', 'heart rate'],
	},
	headache: {
		body: null,
		vital: ['blood pressure'],
	},
	weakness: {
		body: null,
		vital: ['blood pressure', 'heart rate'],
	},
	vomiting: {
		body: null,
		vital: ['blood pressure', 'heart rate', 'temperature'],
	},
	dizziness: {
		body: null,
		vital: ['blood pressure', 'heart rate', 'temperature'],
	},
};
