import styled from 'styled-components';

const SidebarList = styled.ul`    
    width: 100%;
    margin: 0px;
    padding: 0px;
    list-style: none;
    white-space: nowrap;
    display: flex;
flex-direction: column;
height: calc(100vh - 64px);
}
`;

export default SidebarList;
