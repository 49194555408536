import { isUnauthorized } from '../../pages/Login/login.store';

const FILE = 'FILE://TOAST';
const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const GET_STATUS = generateActionType('GET_STATUS');
const GET_TOAST = generateActionType('GET_TOAST');
const CLEAR_ALL = generateActionType('CLEAR_ALL');

// Reducer
const initialState = {
	statusCode: 0,
	isMessage: false,
	messageType: '',
	openSnackbar: false,
	messageContent: {},
};

export function clearToast() {
	return function (dispatch) {
		dispatch({ type: CLEAR_ALL });
	};
}

export function errorHandling(errorData) {
	const { response = {} } = errorData;
	const { data = {}, status = 0 } = response;
	const { message = '', error = [] } = data;
	let validation = {};

	if (status === 422) {
		validation = Object.keys(error).map((e) => {
			const { message = '', key = '' } = error[e];
			return { key, message };
		});
	}

	if (status === 400) {
		validation = { 0: { key: '', message } };
	}

	return function (dispatch) {
		if (isUnauthorized(dispatch, error)) return;
		dispatch({
			type: GET_TOAST,
			isMessage: true,
			messageType: 'Error',
			messageContent: validation,
		});
	};
}

export function inputValidation(errorData) {
	if (errorData.length === 0) {
		return function (dispatch) {
			dispatch(clearToast());
		};
	}
	const validation = errorData.map((e) => {
		const { message = '', key = '' } = e;
		return { key, message };
	});

	return function (dispatch) {
		dispatch({
			type: GET_TOAST,
			isMessage: true,
			messageType: 'Error',
			messageContent: validation,
		});
	};
}

export function showMessage(message = {}) {
	const { isMessage = false, messageType = '', messageContent = {} } = message;
	return function (dispatch) {
		dispatch({
			type: GET_TOAST,
			isMessage,
			messageType,
			messageContent,
		});
	};
}

function toastReducer(state = initialState, action) {
	switch (action.type) {
		case GET_STATUS: {
			return {
				...state,
				statusCode: action.status,
			};
		}
		case GET_TOAST: {
			console.log('WORKONG TOAST', action);
			return {
				...state,
				openSnackbar: true,
				isMessage: action.isMessage,
				messageType: action.messageType,
				messageContent: action.messageContent,
			};
		}
		case CLEAR_ALL:
			return {
				...state,
				statusCode: 0,
				isMessage: false,
				messageType: '',
				messageContent: {},
			};

		default:
			return state;
	}
}

export default toastReducer;
