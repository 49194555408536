import styled from 'styled-components';
import { fontBold, fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';
import Col from './col';

const Row = styled.div`
	display: flex;
	box-sizing: border-box;
	width: 100%;
	margin: ${(props) => props.margin};
	margin-bottom: ${(props) => props.marginBottom};
	margin-top: ${(props) => props.marginTop};
	height: ${(props) => props.height};

	${({ createAbnormalityResponsive }) =>
		createAbnormalityResponsive &&
		`@media screen and (max-width: 768px) {
			flex-direction:column;
			.SymptomBody {
				height:auto !important;
			}
			.abnormalityReportView {
				position:relative;
				width: calc(100% - 20px) !important;
			}
		}
	`};
	${({ responsiveAbnormalityCard }) =>
		responsiveAbnormalityCard &&
		`@media screen and (max-width: 768px) {
			flex-direction:row !important;
			position:relative;
		}
	`};

	${({ temperatureType }) =>
		temperatureType &&
		`margin-bottom: -5px;
	border: 1px solid #6f81a2;
	border-radius: 5px;
	position: relative;
	right: 2px;
	`};
	${({ patientProfileCard }) =>
		patientProfileCard &&
		`padding: 28px;
		padding-top:50px;
		background-color: #ffffff;
		margin-bottom: 0px;
		@media screen and (max-width: 768px) {
			flex-direction:column;
			padding-left:40px;
			p {
				flex-wrap:wrap;
			}
			
		}


	`};
	${({ thePatientMight }) =>
		thePatientMight &&
		`display:flex;
		align-item:flex-start;
		background:#EEF0FF;
		padding:13px 16px;
		border-radius: 8px;
		align-items: center;
		margin-bottom:25px;
		margin-top:12px;
		div{
			max-width:40px;
			flex:0 0 40px;
			margin-right:12px;
			& + div{
				flex:1;
				width:100%;
				max-width:100%;
				text-align:left;
			}
		}
	`}
	${({ chatListItem }) =>
		chatListItem &&
		`height: 86px;
		cursor: pointer;
		transition: ease-in all 0.4s;
		padding:16px 20px 16px 20px;
		border-top: 1px solid ${colors.Border2};
		position: relative;
		:hover, :focus{
			background: #ECEEFA;
		}
		:last-child{ border-bottom: 1px solid ${colors.Border2};}
		@media screen and (max-width: 991px) {
			padding:10px 20px 10px 20px;
			height: auto;
		}
	`};
	${({ DashboardList }) =>
		DashboardList &&
		`border-bottom: 1px solid ${colors.Border2};
		padding:15px 0px 15px 5px;
		height: 85px;
		border-top: 0px;
		:hover, :focus{
			background: #ECEEFA;
		}
	`};
	${({ alignItemsStart }) =>
		alignItemsStart &&
		`align-items: flex-start;
        display:block;
	`}

	${({ isHover }) =>
		isHover &&
		`:hover {
			background: #ECEEFA;
		}
	`}

	${({ WeightTrack }) =>
		WeightTrack &&
		`display:flex;
        align-items: center;
        justify-content:center;
        margin-top:18px;
        p {
            font-size:14px;
            color:#0F255F;
            display:flex;
             align-items: center;
            span {
                width:16px;
                height:16px;
                margin-right:8px;
                flex:0 0 16px;
                display:inline-flex;
            }
        }
        p {

            span {
                background: #0F25E8;

            }
            +p {
                margin-left:24px;
                span {
                background: #90ee90;
            }
            }
        }
	`};

	${({ chatListItemSmall }) =>
		chatListItemSmall &&
		`height: 60px;
		cursor: pointer;
		transition: ease-in all 0.4s;
		padding:0 24px 0 30px;
		border-top: 1px solid ${colors.Border2};
		position: relative;
		align-items: center;
		:hover, :focus{
			background: #ECEEFA;
		}
		:last-child{ border-bottom: 0px;}
		@media screen and (max-width: 991px) {
			padding:10px 20px 10px 20px;
			height: auto;
		}
	`};

	${({ chatHeaderItem }) =>
		chatHeaderItem &&
		`transition: ease-in all 0.4s;
		padding:16px 12px 12px 20px;
		position: relative;
		max-width:100%;
		background: ${colors.White};
		border-bottom: 1px solid ${colors.Border2};
	`};

	${({ chatListItemActive }) =>
		chatListItemActive &&
		`background: #ECEEFA;
		position: relative;
		:after{
			content:'';
			position: absolute;
			width: 100%;
			z-index:2;
			height:3px;
			background: #536DFE;
			left:0px;
			bottom: 0px;
			opacity:1}
			`};

	${({ outgoingChat }) =>
		outgoingChat &&
		`align-items: flex-end;
		flex-direction: column;
		margin-bottom:16px;
	`};
	${({ BackButtonHeader }) =>
		BackButtonHeader &&
		`a{
			font-size: 16px;
			line-height: 22px;
			font-family:  ${fontMedium.familyName};
			color: ${colors.BlueLight};
			text-decoration: none;
			transition: all ease 0.4s;
			white-space: nowrap;
			:hover{
				color: ${colors.BlueBase};
				cursor: pointer;
			}
		}
	`};
	${({ dnone }) =>
		dnone &&
		`display: none;
	`};
	${({ PatientValueScroll }) =>
		PatientValueScroll &&
		`max-height: 40vh;
		overflow: auto;
	`};
	${({ incomingingChat }) =>
		incomingingChat &&
		`align-items: flex-start;
		flex-direction: column;
		margin-bottom:16px;
	`};

	${({ dateSeperator }) =>
		dateSeperator &&
		`align-items: center;
		justify-content: center;
		padding:10px 0;
		position: relative;
		span{
			font-family:  ${fontMedium.familyName};
			color: ${colors.BlueBase};
			font-size: 14px;
			line-height: 16px;
			background: ${colors.White};
			padding:5px 10px;
			position: relative;
			z-index: 2;
		}
		:before{
			content: '';
			width: 100%;
			height: 1px;
			background: ${colors.Border2};
			position: absolute;
			left:0;
			top:22px;
			z-index: 1;
		}
	`};

	${({ chatActionsWrapper }) =>
		chatActionsWrapper &&
		`padding:20px 30px;
		border-top: 1px solid${colors.Border2};
		@media screen and (max-width: 768px) {
			padding:10px 20px 10px 10px;
		}
	`};
	${({ dietListItem }) =>
		dietListItem &&
		`transition: ease-in all 0.4s;
		padding:20px 0px 24px 20px;
		border-bottom: 1px solid ${colors.Border2};
		border-top: 1px solid ${colors.Border2};
		margin-bottom:-1px;
		position: relative;
		// cursor: pointer;
		// :hover, :focus{
		// 	background: #eef0ff;
		// }
	`};
	${({ dietListActive }) =>
		dietListActive &&
		`background: #eef0ff;
	`};
	${({ noBorderTop }) =>
		noBorderTop &&
		`border-top: 0px!important;
	`};
	${({ rightAlign }) =>
		rightAlign &&
		`justify-content: flex-end;
	`};
	${({ MiddleAlign }) =>
		MiddleAlign &&
		`align-items: center;
`};
	${({ centerAlign }) =>
		centerAlign &&
		`justify-content: center;
	`};
	${({ borderTop }) =>
		borderTop &&
		`border-top: 0.5px solid ${colors.GreyEC};
		padding-top:25px;
	`};
	${({ borderBottom }) =>
		borderBottom &&
		`border-bottom: 1px solid ${colors.GreyEC};
		padding-top:28px;
		margin-bottom:28px;
	`};
	${({ borderBottomPrint }) =>
		borderBottomPrint &&
		`border-bottom: 3px solid ${colors.GreyEC};
		padding-bottom:15px;
		margin-bottom:20px;
	`};
	${({ TimeLineHeader }) =>
		TimeLineHeader &&
		`border-bottom: 1px solid ${colors.GreyEC};
		padding:20px 25px;
	`};

	${({ flexColumn }) =>
		flexColumn &&
		`flex-direction: column;;
	`};

	${({ RowMDnoFlex }) =>
		RowMDnoFlex &&
		`	@media screen and (max-width: 1200px) {
			display: inline-block;
		}
	`};
	${({ RowXSnoFlex }) =>
		RowXSnoFlex &&
		`	@media screen and (max-width: 767px) {
			flex-direction: column;
		}
	`};
	${({ RowSMnoFlex }) =>
		RowSMnoFlex &&
		`	@media screen and (max-width: 991px) {
			display: inline-block;
		}
	`};
	${({ ColorBox }) =>
		ColorBox &&
		`background: #E1E5ED;
		border-radius: 4px;
		padding:10px 10px 8px;
		margin-bottom:10px;
	`};
	${({ footerFixed }) =>
		footerFixed &&
		`background: ${colors.White};
		padding:15px 10px;
		position: fixed;
		right:0;
		bottom:0px;
		border-top: 1px solid ${colors.Border2};
		a{
			padding:0px;
		}
	`};
	${({ footerDefault }) =>
		footerDefault &&
		`padding:15px 24px 0;
		border-top: 1px solid ${colors.Border2};
		margin: 20px -24px -10px -24px;
		width: auto;
	`};
	${({ ReportMoreInfo }) =>
		ReportMoreInfo &&
		`margin-top:10px;
		padding-left:10px;
		flex-direction: column;
		${Row}{
			padding-top:10px;
		}
	`};
	${({ ReportMoreInfoShow }) =>
		ReportMoreInfoShow &&
		`padding:10px;
		background: #E1E5ED;
		border-radius: 4px;
	`};
	${({ TemplateBoxRow }) =>
		TemplateBoxRow &&
		`overflow: auto;
	`};
	${({ IconPreview }) =>
		IconPreview &&
		`
		margin:30px;
		flex-wrap: wrap;
		span{
			font-size: 20px;
			min-width: 160px;
			min-height: 90px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			p{
				margin-top:5px;
				font-size: 13px;
			}
		}
	`};

	${({ dietDropdownBlock }) =>
		dietDropdownBlock &&
		`button{
			padding: 0px;
			&:hover, &:focus{
				background: transparent !important;
				span{
					background: rgba(126, 157, 240, 0.2);
				}
			}
			+ div{
				width:205px;
				padding: 15px;
				right:inherit;
				left: 0px;
				top: 70px;
				:before {
					content: '';
					position: absolute;
					top: -10px;
					left:25px;
					z-index: -1;
					width: 0px;
					height: 0px;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-bottom: 10px solid ${colors.White};
				}
				:after{
					content: '';
					position: absolute;
					top: -11px;
					left: 24px;
					z-index: -2;
					width: 0px;
					height: 0px;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
					border-bottom: 11px solid #DFE1E5;
					}
				}
			}
		}
    `};
	${({ hide }) => hide && `display: none;`};

	${({ attachArea }) =>
		attachArea &&
		`width: 100%;
            height: 100%;
            position: absolute;
            // z-index: 999;
            margin: auto;
            background-color: #fff;
            overflow:hidden;
            img{
                width: auto;
                height:100%;
                margin:auto;
            }
            span {
                display:block !important;
                position: absolute;
                right: 10px;
                color: ${colors.White};
                cursor: pointer;
                padding: 8px;
                border-radius: 30px;
                background: #999999;
                font-size: 12px;
            }
            button{
                display:block !important;
                position: absolute;
                right: 10px;
                top: 10px;
                background-color: transparent;
                border: none;
            }
     `};
	${({ cropArea }) =>
		cropArea &&
		`height: 87%;
        position: absolute;
        top: 0;
    `};
	${({ themesBtnBlock }) =>
		themesBtnBlock &&
		`@media screen and (max-width: 640px) {
            button{
                min-width:auto;
            }
        }
`};

	${({ detailsFilter }) =>
		detailsFilter &&
		`div+div{
            padding-left:20px;
            @media screen and (max-width: 640px) {
                padding-left:0;
                margin:20px 0 0 0;
            }
        }
        div{
          @media screen and (max-width: 640px) {
                margin:0;
            }
        }
`};

	margin: ${(props) => props.margin};
	padding: ${(props) => props.padding};
	.weightTrackingInfoHeader {
		display: flex;
		flex-direction: column;
		justify-content: center;
		h3 {
			font-size: 12px;
			line-height: 14px;
			font-family: ${fontMedium.familyName};
			color: #1e3269;
		}
		h1 {
			font-size: 24px;
			line-height: 29px;
			font-family: ${fontBold.familyName};
			color: #1e3269;
			margin: 5px 0;
		}
		h2 {
			font-size: 13px;
			line-height: 14px;
			font-family: ${fontMedium.familyName};
			color: #1e3269;
			margin-top: 12px;
		}
		p {
			span {
				margin: 0 !important;
			}
		}
	}
	.weightTrackingInfoContent {
		background: #ebfbff;
		border-radius: 10px;
		padding: 16px;
		display: flex;
		flex-direction: column;
		text-align: left;
		align-items: flex-start !important;
		margin-top: 22px;
		h1 {
			font-size: 14px;
			line-height: 18px;
			font-family: ${fontBold.familyName};
			color: #1e3269;
		}
		p {
			font-size: 12px;
			line-height: 18px;
			color: #0f255f;
			opacity: 0.7;
			font-family: ${fontMedium.familyName};
			margin: 4px 0 16px;
			white-space: normal;
		}
		h2 {
			font-size: 12px;
			line-height: 14px;
			font-family: ${fontBold.familyName};
			color: #1e3269;
		}
		div {
			display: flex;
			align-items: center;
			margin-top: 14px;

			p {
				margin: 0 !important;
				font-size: 12px;
				line-height: 14px;
				display: flex;
				align-items: center;
				color: #0f255f;
			}
		}
	}
	${({ PatientInfoGender }) =>
		PatientInfoGender &&
		`@media screen and (max-width: 480px) {
			flex-direction:column;
			${Col}{
				width:100%!important;
				padding-right:0;
				div{
					div{
						button{
							margin-bottom:10px;
						}
						margin-bottom:0px;
					}
				}
			}
		}
	`}
	${({ PatientInfoPhoneNumber }) =>
		PatientInfoPhoneNumber &&
		`.CountryCode{
			max-width: 140px;
			margin-right: 10px;
		}
		@media screen and (max-width: 480px) {
			flex-direction:column;
			.CountryCode{
				max-width: 100%;
				margin-right: 0;
			}
			${Col}{
				width:100%!important;
				padding-right:0;
				div{
					div{
						width:100%;
						button{
							margin-bottom:10px;
							width:100%;
						}
						margin-bottom:0px;
					}
				}
			}
		}
	`}
		${({ ClinicalDocs }) =>
		ClinicalDocs &&
		`@media screen and (max-width: 860px) {
			flex-direction:column;
			div + div{
				margin-top:10px;
			}
			
		}
	`}
	${({ MedicalRecordNumber }) =>
		MedicalRecordNumber &&
		`display:flex;
		flex-direction: column;
    	text-align: right;
		h3{
			padding-right:0;
		}
	`}
	${({ weightTracking }) =>
		weightTracking &&
		`@media screen and (max-width: 768px) {
			flex-direction:column;
			div{
				p{
					font-size:11px;
				}
			}
			div + div{
				margin-top:10px;
				button{
					width:100%;
				}
			}

		}
	`}
`;

export default Row;
