import styled from 'styled-components';
import { colors } from '../colors';

const ModalHeader = styled.div`
	position: relative;
	padding: 30px 24px 0;
	display: flex;
	justify-content: space-between;
	padding: ${(props) => props.padding};
	padding-bottom: 20px !important;
	p {
		font-size: 14px;
		line-height: 22px;
		color: #585858;
	}
	h3 {
		font-size: 20px;
	}
	${({ BorderHeader }) =>
		BorderHeader &&
		`border-bottom: 1px solid ${colors.Border2};
		padding-bottom:24px;
	`}
	${({ FlexColumn }) =>
		FlexColumn &&
		`flex-direction:column;
	`}
	${({ endSessionModalHeader }) =>
		endSessionModalHeader &&
		`display:flex;
		// padding-top:0!important;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		h1{
			padding: 0 25px;
			word-break: break-word;
			white-space: normal;
		}
	`}
`;

export default ModalHeader;
