import React from 'react';
import styled from 'styled-components';
import { colors } from '../colors';

// icon-arrow-right-double

const IconSpan = styled.span`
	position: relative;
	box-sizing: border-box;
	font-family: 'icon-font';
	font-size: 12px !important;
	${({ color }) => color && `color: ${color} !important; `}
	${({ borderBox }) =>
		borderBox &&
		`
		background: rgba(126, 157, 240, 0.09);
		border: 1px dashed #6C7BA4;
		box-sizing: border-box;
		color: #6C7BA4;
		border-radius: 4px;
		padding:20px;
		display: inline-block;
		transition: ease all 0.4s;
		&:hover{
			background: rgba(126, 157, 240, 0.2);
		}
	`}
	${({ Selected }) =>
		Selected &&
		`
		color: ${colors.BlueBase};
		padding:8px;
		font-size:40px!important;
		border: 1px solid rgba(126, 157, 240, 0.09);
	`}
`;

const Icon = (props) => {
	const { iconName = '', handleClick, onKeyUp } = props;
	return (
		<IconSpan
			{...props}
			role="button"
			tabIndex="0"
			aria-label={iconName}
			className={iconName}
			onClick={handleClick}
			onKeyUp={onKeyUp}
		/>
	);
};

Icon.defaultProps = {
	onKeyUp: () => {},
	handleClick: () => {},
};

export default Icon;
